<template>
  <div class="forget-container">
    <div class="forget-form-wrapper">
      <p class="fontsize_24 color_222 fontweight_600 margin_bot16">{{currentData.title}}</p>
      <p class="fontsize_16 margin_bot32 flex flex_acenter forget_back_login"><i @click="tapLogin" class="color_1373E6 icon-back-login-blue fontsize_12 pointer"></i><span @click="tapLogin" class="padding_left8 color_1373E6 pointer">{{currentData.tips2}}</span></p>
      <el-form @submit.native.prevent :model="ruleForm" status-icon :rules="rules" :show-message="false" ref="ruleForm" label-width="120px" label-position="left" class="login-form">
          <el-form-item :prop="item.props" label-width="0" v-for="(item, index) in currentData[!isNextStep ? mode : 'nextStep']" :key="item.name">
            <el-input :type="getInputType(item.inputType, item.props)" v-model="ruleForm[item.props]" autocomplete="off" :placeholder="item.placeholder" class="login-input-wrapper">
              <span  v-if="item.propName === 'mobileCode' && !mobileCodeData.hasCode" slot="suffix" class="fontsize_12 color_E74362 pointer valid-code-btn hoverColor_CC3350" :class="{'is-disabled':!ruleForm.account}" @click="getMobileCode">获取验证码</span>
              <span  v-else-if="item.propName === 'mobileCode' && mobileCodeData.hasCode" slot="suffix" class="fontsize_12 color_A1A1A1 pointer valid-code-btn">重新获取(<i style="font-style: normal;">{{mobileCodeData.countdownTime}}</i>)</span>
            </el-input>
            <div class="input-error-msg" v-if="errMsgSet[item.props]">
              <i class="icon-warning fontsize_12"></i><span class="padding_left8 color_E74362 fontsize_12">{{errMsgSet[item.props]}}</span>
            </div>
            <div v-if="index === 0 && !isNextStep && otherModes.length > 0" style="line-height:32px;">
              <p class="fontsize_14 color_A1A1A1">改用我的<span class="padding_0_4 color_E74362 pointer hoverColor_D0435E" @click="changeMode(otherModes[0].type)">{{otherModes[0].title}}</span>找回</p>
            </div>
          </el-form-item>
          <div v-if="isNextStep" class="flex flex_acenter flex_jcbetween margin_bot24">
            <checkBox key="showpass" 
              :value="isShowPass" :label="'显示密码'" :labelFontSize="12" :scale="0.6" :labelPaddingLeft="5"
              @change="changeShowPass" class="show-pwd-checkbox">
            </checkBox>
          </div>
          <customButton  v-if="isNextStep" @click="submitForm('ruleForm')" color="#E74362" hoverBgColor="#CC3350" width="100%" :roundPX="4" :height="48"><span class="fontsize_14">{{currentData.submitBtn.name}}</span></customButton>
          <customButton  v-else @click="tapNextStep('ruleForm')" color="#E74362" hoverBgColor="#CC3350" width="100%" :roundPX="4" :height="48"><span class="fontsize_14">{{currentData.nextBtn.name}}</span></customButton>
      </el-form>
    </div>
  </div>
</template>

<script>
import { sendOfLoginMobileCode, sendEmailCodeOfLogin, forgetCodeWithEmail, forgetCodeWithPhone, setNewPwd } from '@/libs/api/login'
import { mapActions } from 'vuex'
import customButton from "@/renderer/components/common/button.vue";
import checkBox from "@/renderer/components/common/checkBox.vue";
export default {
  components: {
    customButton,
    checkBox
  },
  data() {
    var validateUserName = (rule, value, callback) => {
      let errorMsgObj = {
        "phone":"手机号格式不正确",
        "email":"邮箱格式不正确",
      };
      // console.log("rule",rule)
      let reg = this.mode==='phone' ? /^1\d{10}$/ : /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      if (value === '' || !reg.test(value)) {
        callback(new Error(errorMsgObj[this.mode]));
        // this.errMsgSet[rule.field] = errorMsgObj[this.mode];
      } else {
        callback();
        // this.errMsgSet[rule.field] =  "";
      }
    };
    var validatePass = (rule, value, callback) => {
      let reg = /^[A-Za-z0-9]+$/;
      if (value === '') {
        callback(new Error('密码不正确'));
        // this.errMsgSet[rule.field] = "密码不正确";
      }else if(value && value.length < 8) {
        callback(new Error('请输入不少于8位的密码'));
        // this.errMsgSet[rule.field] = "请输入不少于8位的密码";
      } else if(value && !reg.test(value)) {
        callback(new Error('密码 只能由字母和数字组成'));
        // this.errMsgSet[rule.field] = "密码 只能由字母和数字组成";
      }else {
        callback();
        // this.errMsgSet[rule.field] =  "";
      }
    };
    var validateRePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('确认密码不正确'));
        // this.errMsgSet[rule.field] = "确认密码不正确";
      }else if(value !== this.ruleForm.password) {
        callback(new Error('再次输入的密码不相同'));
        // this.errMsgSet[rule.field] = "再次输入的密码不相同";
      }else {
        callback();
        // this.errMsgSet[rule.field] =  "";
      }
    };
    var validateCode = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('验证码不正确'));
        // this.errMsgSet[rule.field] = "验证码不正确";
      } else {
        callback();
        // this.errMsgSet[rule.field] =  "";
      }
    };
    return {
      type:"forget",
      mode:"email", // phone - 密码找回方式
      isNextStep: false, //是否下一步
      isShowPass:false, //是否显示密码
      mobileCodeData: {
        hasCode: false,
        timer:null,
        countdownTime: 30, //倒计时s
        requesting:false, //请求中
      },
      nextStepData: {

      },
      contentData: {
        forget: {
          title: "找回密码",
          tips2:"返回登录",
          modeArr:[
            {
              type:"email",
              title:"邮箱",
            },
            {
              type:"phone",
              title:"手机号",
            }
          ],
          phone:[
            {
              props:"account", //绑定字段
              inputType:"text",
              propName:"phone", //
              name:"手机号",
              placeholder:"手机号",
            },
            {
              props:"verification_code",
              inputType:"text",
              propName:"mobileCode", //
              name: "验证码",
              placeholder:"验证码",
            }
          ],
          email: [
            {
              props:"account",
              inputType:"email",
              propName:"email", //
              name: "邮箱",
              placeholder:"邮箱",
            },
            {
              props:"verification_code",
              inputType:"text",
              propName:"mobileCode", //
              name: "验证码",
              placeholder:"验证码",
            }
          ],
          nextStep: [
            {
              props:"password",
              inputType:"password",
              propName:"password", //
              name: "密码",
              placeholder:"密码",
              width:"50%"
            },
            {
              props:"password_confirmation",
              inputType:"password",
              propName:"repassword", //
              name: "确认密码",
              placeholder:"确认新密码",
              width:"50%"
            }
          ],
          showPassword: {
            name:"显示确认密码",
            form_type:"checkbox"
          },
          nextBtn: {
            name:"下一步",
            type:"nextStep"
          },
          submitBtn: {
            name:"完成",
            type:"submit"
          }
        },
      },
      ruleForm: {
        account: '',
        password: '',
        verification_code: '',
        password_confirmation: ''
      },
      errMsgSet: {
        account:"",
        password: "",
        verification_code: "",
        password_confirmation: "",
      },
      rules: {
        account: [
          { validator: validateUserName, trigger: 'submit' }
        ],
        password: [
          { validator: validatePass, trigger: 'submit' }
        ],
        verification_code: [
          { validator: validateCode, trigger: 'submit' }
        ],
        password_confirmation: [
          { validator: validateRePass, trigger: 'submit' }
        ],
      },
      propMap: { //校验filed
        mobile: "account",
        email:"account",
        password: "password",
        password_confirmation:"password_confirmation",
        verification_code: "verification_code",
      },
    };
  },
  computed: {
    currentData() {
      return this.contentData[this.type];
    },
    otherModes() { //其他登录方式-注册方式
      return this.currentData.modeArr.filter(v => v.type !== this.mode) || [];
    },
  },
  beforeDestroy() {
    this.mobileCodeData.timer && clearInterval(this.mobileCodeData.timer);
  },
  methods: {
    tapLogin() {
      // 跳转登录
      this.$router.push({name:"Login"});
    },
    tapNextStep(formName) {
      // 重置错误信息
      Object.keys(this.errMsgSet).forEach(v => this.errMsgSet[v] = "");

      this.mobileCodeData.timer && clearInterval(this.mobileCodeData.timer);
      this.$refs[formName].validate((valid,obj) => {
        if (valid) {
          this.mode === 'email' ? this.putEmailOfCode() : this.putPhoneOfCode();
        } else {
          console.log('error submit!!');
          Object.keys(obj).forEach(v => {
            this.errMsgSet[v] = obj[v][0].message;
          });
          return false;
        }
      });
    },
    putPhoneOfCode() {
      // 提交手机+验证码
      let {account, verification_code} = this.ruleForm;
      forgetCodeWithPhone({mobile: account, verification_code}).then(res => {
        if(res.errorcode == 0) {
          this.nextStepData = res.data;
          this.isNextStep = true;
        }else if(res.errorcode == 1 && res.data){
          // 错误提示
          Object.keys(res.data).forEach(v => {
            this.errMsgSet[this.propMap[v]] = res.data[v][0];
          });
        }
      }).catch();
    },
    putEmailOfCode() {
      // 提交邮箱+验证码
      let {account, verification_code} = this.ruleForm;
      forgetCodeWithEmail({email: account, verification_code}).then(res => {
        if(res.errorcode == 0) {
          this.nextStepData = res.data;
          this.isNextStep = true;
        }else if(res.errorcode == 1 && res.data){
          // 错误提示
          Object.keys(res.data).forEach(v => {
            this.errMsgSet[this.propMap[v]] = res.data[v][0];
          });
        }
      }).catch();
    },
    submitForm(formName) {
      // 重置错误信息
      Object.keys(this.errMsgSet).forEach(v => this.errMsgSet[v] = "");

      this.$refs[formName].validate((valid, obj) => {
        if (valid) {
          this.savePwd();
        } else {
          console.log('error submit!!');
          Object.keys(obj).forEach(v => {
            this.errMsgSet[v] = obj[v][0].message;
          });
          return false;
        }
      });
    },
    savePwd(args) {
      let { account, password, verification_code, password_confirmation } = this.ruleForm;
      let forget_token = this.nextStepData.forget_token;
      let params = {
        forget_type:this.mode==="phone" ? "mobile" : "email",
        password,
        password_confirmation,
        forget_token
      };
      if(this.mode==="phone") {
        params.mobile = account;
      }
      if(this.mode==="email") {
        params.email = account;
      }
      setNewPwd(params).then( (res=>{
        if(res && res.errorcode == 0) {
          this.$message.success("修改密码成功！");
          this.$router.push({name:'Login'});
        }
      })).catch((err)=>{});
      
    },
    resetForm(formName) {
      formName && this.$refs[formName].resetFields();
      Object.keys(this.errMsgSet).forEach(v => this.errMsgSet[v] = "");
    },
    changeShowPass(value) {
      // 切换-是否显示密码
      this.isShowPass = value;
    },
    getInputType(type, props) {
      // 账号模式-显示密码时，密码框改为文本框
      if(props.indexOf('password') > -1 && this.isShowPass) return "text";
      return type;
    },
    changeMode(mode) {
      // 改变登录/注册方式
      this.mode = mode;
      // 重置表单
      this.resetForm("ruleForm");
    },
    getMobileCode() {
      // 获取验证码
      let mobile = this.ruleForm.account;
      // 请求中
      if(this.mobileCodeData.requesting) return;
      // 倒计时中
      if(this.mobileCodeData.hasCode) return;
      if(!mobile) {
        let errMsg = this.mode == "email" ? "请输入邮箱!" : "请输入手机号！";
        this.$message.warning(errMsg);
        return;
      }
      this.mobileCodeData.requesting = true;
      this.mobileCodeData.hasCode = true;
      // 倒计时
      this.mobileCodeData.countdownTime --;
      this.mobileCodeData.timer = setInterval(()=>{
        if(this.mobileCodeData.countdownTime <= 1) {
          clearInterval(this.mobileCodeData.timer);
          this.mobileCodeData.hasCode = false;
          this.mobileCodeData.countdownTime = 30;
        }
        this.mobileCodeData.countdownTime --;
      },1000)
      // 发送验证码
      this.mode === 'phone' ? this.sendCodeOfPhone(mobile) : this.sendCodeOfEmail(mobile);
    },
    sendCodeOfPhone(mobile) {
      // 发送手机 验证码
      sendOfLoginMobileCode(mobile).then(res => {
        this.mobileCodeData.requesting = false;
        if(res.errorcode == 0) {
          this.$message.success(res.msg);
        }else {
          // this.$message.warning(res.msg);
          this.mobileCodeData.hasCode = false;
          clearInterval(this.mobileCodeData.timer);
          this.mobileCodeData.countdownTime = 30;
        }
      }).catch(()=>{
        this.mobileCodeData.requesting = false;
        this.mobileCodeData.hasCode = false;
        clearInterval(this.mobileCodeData.timer);
        this.mobileCodeData.countdownTime = 30;
        this.$message.warning("验证码发送失败！");
      });
    },
    sendCodeOfEmail(mobile) {
      // 发送邮箱 验证码
      sendEmailCodeOfLogin(mobile).then(res => {
        this.mobileCodeData.requesting = false;
        if(res.errorcode == 0) {
          this.$message.success(res.msg);
        }else {
          // this.$message.warning(res.msg);
          this.mobileCodeData.hasCode = false;
          clearInterval(this.mobileCodeData.timer);
          this.mobileCodeData.countdownTime = 30;
        }
      }).catch(()=>{
        this.mobileCodeData.requesting = false;
        this.mobileCodeData.hasCode = false;
        clearInterval(this.mobileCodeData.timer);
        this.mobileCodeData.countdownTime = 30;
        this.$message.warning("验证码发送失败！");
      });
    },
  }
}
</script>

<style lang="less" scoped>
    .forget-container {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
    .forget-form-wrapper {
      box-sizing: border-box;
      border-radius: 4px;
      border: 1px solid #ccc;
      padding: 40px 40px 47px;
      width: 472px;
      height: 519px;
      box-shadow: 0 2px 21px 0 rgba(0,0,0,0.15);
      background: #FFFFFF;
    }
    .login-form {
        .el-form-item {
            margin-bottom: 24px;
        }
    }
    .other-mode-title {
      position: relative;
      padding: 24px 0;
      &::before,
      &::after {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        display: block;
        content: "";
        width: 138px;
        height: 1px;
        background-color: #D4D4D4;
      }
      &::before {
        left: 0;
      }
      &::after {
        right: 0;
      }
    }
    // 微信登陆
    .wechat-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      border-radius: 4px;
      box-shadow: 0 0 4px 0 rgba(0,0,0,0.15);
      padding: 22px 0 13px;
      width: 100%;
      height: 235px;
      background: #FFFFFF;
      .wechat-code-wrapper {
        box-sizing: border-box;
        // border: 4px solid #E74362;
        width: 150px;
        height: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-image: url(~@/assets/user/red_frame1@3x.png), url(~@/assets/user/red_frame2@3x.png),
          url(~@/assets/user/red_frame3@3x.png), url(~@/assets/user/red_frame4@3x.png);
        background-repeat: no-repeat;
        background-position: left top, right top, left bottom, right bottom;
        background-size: 25px 25px;
      }
      .wechat-code-img {
        width: 140px;
        height: 140px;
        object-fit: fill;
      }
    }
    .valid-code-btn {
      padding: 4px 15px;
      &.is-disabled {
        color: #A1A1A1;
      }
    }
    .forget_back_login {
      &:hover {
        i::before,span {
          color: #126AD4;
        }
      }
    }
</style>